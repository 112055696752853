body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f2f2f2;

	height: 100vh;
}

#root {
	height: 100%;
}

html {
	overflow-y: hidden;
	font-size: 12px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button,
input,
textarea {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 20px;
}

.fs-14 {
	font-size: 14px;
}

.fs-20 {
	font-size: 20px;
}


/* 
TODO: REMOVE ARROW FOR INPUT TYPE = NUMBER

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
} */
